import { ModifierType } from 'root/types';
import type {
  PopulatedMenu,
  PopulatedSection,
  ItemWithLabelsAndVariants,
  Menu,
  Section,
  Item,
  LabelWithSvg,
  Modifier,
  ModifierWithPrice,
  ShowcasePopulatedItemWithLabelsSvg,
  PriceVariant,
} from 'root/types';

export const populateMenu = (
  menu: Menu,
  sections: Section[],
  items: Item[],
  labels: LabelWithSvg[],
  modifiers: Modifier[],
  populatedItems: ShowcasePopulatedItemWithLabelsSvg[],
  shouldFormatModifiersPrice?: boolean,
  isPopulatedItemExperimentEnabled?: boolean
): PopulatedMenu => {
  const menuSections = menu.sectionIds
    ?.map((sectionId) => sections.find((section) => section.id === sectionId))
    ?.filter((section) => !!section) as PopulatedSection[];

  const sectionsWithItems = menuSections?.map((section) => {
    let sectionItems: Item[] | ShowcasePopulatedItemWithLabelsSvg[];

    if (isPopulatedItemExperimentEnabled) {
      sectionItems = section?.itemIds
        ?.map((itemId) => populatedItems.find((item) => item.id === itemId))
        ?.filter((item) => !!item && item.visible !== false) as ShowcasePopulatedItemWithLabelsSvg[];
    } else {
      sectionItems = section?.itemIds
        ?.map((itemId) => items.find((item) => item.id === itemId))
        ?.filter((item) => !!item && item.visible !== false) as Item[];
    }

    const itemsWithLabelsAndVariants = sectionItems?.map((item) =>
      isPopulatedItemExperimentEnabled
        ? (item as ShowcasePopulatedItemWithLabelsSvg)
        : ({
            ...item,
            labels: item?.labels
              ?.map((itemLabel) => labels.find((label) => label.id === itemLabel.id))
              ?.filter((label) => !!label),
            // @ts-expect-error
            priceVariants: (item?.priceVariants?.priceVariants as PriceVariant[])
              ?.map((priceVariant: PriceVariant) => {
                const priceVariantAsModifier = modifiers.find(
                  (modifier) => modifier.id === priceVariant.modifierId && modifier.type === ModifierType.VARIANT
                );
                return {
                  ...priceVariantAsModifier,
                  price: shouldFormatModifiersPrice ? priceVariant.price?.replace(',', '') : priceVariant.price,
                } as ModifierWithPrice;
              })
              .filter((modifier) => !!modifier),
          } as ItemWithLabelsAndVariants)
    );

    return {
      ...section,
      items: itemsWithLabelsAndVariants,
    };
  }) as PopulatedSection[];

  return {
    ...menu,
    sections: sectionsWithItems,
  };
};
