import { initState } from '@wix/velocycle-mobx';
import { getPresetNumberOfColumns } from 'root/components/Columns/utils';
import { MENU_STATES } from 'root/components/Menu/consts';
import type { PlaceholderImageDisplayValue, ZeroPriceDisplayValue } from 'root/components/Menu/panels/Settings/types';
import type { MenuState } from 'root/components/Menu/types';
import type { PopulatedMenu, Preset, Section } from 'root/types';
import { MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA } from 'root/utils/widgetsDefaultData';

export type RootState = {
  menuState: MenuState;
  menu: PopulatedMenu | undefined;
  navigationBarItems: string[];
  preset: Preset;
  numberOfColumns: number;
  columns: Section[][];
  shouldDisplayCurrency: boolean;
  shouldDisplayVariantCurrency: boolean;
  zeroPriceDisplayOption: ZeroPriceDisplayValue;
  zeroPriceDisplaySpecificSectionIds: string[] | undefined;
  placeholderImageDisplayValue: PlaceholderImageDisplayValue;
  sectionsWithPlaceholderImageIds: string[] | undefined;
  placeholderImage: string | undefined;
};

export const { state } = initState<RootState>({
  menuState: MENU_STATES.menu,
  menu: undefined,
  navigationBarItems: [],
  preset: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.preset,
  numberOfColumns: getPresetNumberOfColumns(MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.preset),
  columns: [],
  shouldDisplayCurrency: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.shouldDisplayCurrency,
  shouldDisplayVariantCurrency: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.shouldDisplayVariantCurrency,
  zeroPriceDisplayOption: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.zeroPriceDisplayOption,
  zeroPriceDisplaySpecificSectionIds: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.zeroPriceDisplaySpecificSectionIds,
  placeholderImageDisplayValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.placeholderImageDisplayValue,
  sectionsWithPlaceholderImageIds: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.sectionsWithPlaceholderImageIds,
  placeholderImage: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.placeholderImage,
});
