import type { Label } from 'root/types';
import type { HttpClientFactory } from './utils/types';
import { listLabels, getLabel } from '@wix/ambassador-restaurants-menus-v1-item-label/http';

export const LabelsClient: HttpClientFactory<Label> = (httpClient) => ({
  get: async ({ id }) => {
    return httpClient
      .request(getLabel({ labelId: id }))
      .then((response) => ({ data: response.data.label as Label }))
      .catch((e) => {
        throw new Error(`Error when calling getLabel with id ${id}- ${e}`);
      });
  },
  getAll: async ({ ids }) => {
    // TODO: add support for pagination on server side https://jira.wixpress.com/browse/RST-6576
    return httpClient
      .request(listLabels({}))
      .then((response) => ({ data: response.data.labels as Label[] }))
      .catch((e) => {
        throw new Error(`Error when calling listLabels- ${e}`);
      });
  },
});
