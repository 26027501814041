import type { Item, Section } from 'root/types';

export const didChange = <T extends Item | Section>(prevEntities: T[] = [], entities: T[] = []) => {
  return (
    prevEntities.length !== entities.length ||
    prevEntities.some((prevEntity, index) => {
      const entity = entities[index];
      return prevEntity.id !== entity.id || prevEntity.revision !== entity.revision;
    })
  );
};
