import { didChange } from 'root/utils/lifeCycle';
import model from './model';
import type { VariantWidgetProps } from './types';
import { getPriceFormatter } from 'root/utils/currency';
import { VariantsController } from './variantsController';

export default model.createController(({ $bind, $widget, $w, flowAPI, $props }) => {
  const priceFormatter = getPriceFormatter(flowAPI);
  const experiments = flowAPI.experiments;
  const isUseBindExperimentEnabled = experiments.enabled('specs.restaurants.useBindInsteadOfW');
  const variantsController = new VariantsController($w, $bind, $props, priceFormatter, isUseBindExperimentEnabled);

  if (!isUseBindExperimentEnabled) {
    $widget.onPropsChanged((prevProps, nextProps) => {
      const { priceVariants, shouldDisplayVariantCurrency, shouldDisplayZeroPrice }: VariantWidgetProps =
        nextProps.data;
      const {
        priceVariants: prevPriceVariants,
        shouldDisplayVariantCurrency: prevShouldDisplayVariantCurrency,
        shouldDisplayZeroPrice: prevShouldDisplayZeroPrice,
      }: VariantWidgetProps = prevProps.data;

      if (didChange(prevPriceVariants, priceVariants)) {
        variantsController.initWithW(priceVariants, shouldDisplayVariantCurrency, shouldDisplayZeroPrice);
      }

      if (shouldDisplayVariantCurrency !== prevShouldDisplayVariantCurrency) {
        variantsController.updateCurrency(shouldDisplayVariantCurrency);
      }

      if (shouldDisplayZeroPrice !== prevShouldDisplayZeroPrice) {
        variantsController.updateShouldDisplayZeroPrice(shouldDisplayZeroPrice);
      }
    });
  }

  return {
    pageReady: async () => {
      if (isUseBindExperimentEnabled) {
        variantsController.init(priceFormatter);
      } else {
        const { priceVariants, shouldDisplayVariantCurrency, shouldDisplayZeroPrice }: VariantWidgetProps =
          $widget.props.data;
        variantsController.initWithW(priceVariants, shouldDisplayVariantCurrency, shouldDisplayZeroPrice);
      }
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
