import type { Item, Label, Modifier, PopulatedMenu, ShowcasePopulatedItem } from 'root/types';
import type { GetAllResponse, HttpClientFactory } from './utils/types';
import { MenusClient } from './MenusClient';
import { SectionsClient } from './SectionsClient';
import { ItemsClient, PopulatedItemsClient } from './ItemsClient';
import { LabelsClient } from './LabelsClient';
import { populateMenu } from 'root/api/utils/populateMenu';
import { completeMissingEntities } from 'root/api/utils/completeMissingEntities';
import { ModifiersClient } from './ModifiersClient';
import { getLabelsWithSvgElement } from 'root/utils/labels';

export const PopulatedMenuClient: HttpClientFactory<PopulatedMenu> = (httpClient) => {
  const MenusApi = MenusClient(httpClient);
  const SectionsApi = SectionsClient(httpClient);
  const ItemsApi = ItemsClient(httpClient);
  const LabelsApi = LabelsClient(httpClient);
  const ModifiersApi = ModifiersClient(httpClient);
  const PopulatedItemsApi = PopulatedItemsClient(httpClient);

  return {
    getAll: async ({
      paging,
      shouldFormatModifiersPrice,
      isPopulatedItemExperimentEnabled,
      isLabelLayouterWidgetExperimentEnabled,
    }) => {
      let items: GetAllResponse<Item> | undefined;
      let populatedItems: GetAllResponse<ShowcasePopulatedItem> | undefined;
      let labels: GetAllResponse<Label> | undefined;
      let modifiers: GetAllResponse<Modifier> | undefined;
      const [menus, sections] = await Promise.all([MenusApi.getAll({ paging }), SectionsApi.getAll({ paging })]);

      if (isPopulatedItemExperimentEnabled) {
        populatedItems = await PopulatedItemsApi.getAll({ paging });
      } else {
        [items, labels, modifiers] = await Promise.all([
          ItemsApi.getAll({ paging }),
          LabelsApi.getAll({ paging }),
          ModifiersApi.getAll({ paging }),
        ]);
      }

      await completeMissingEntities(
        menus.data,
        sections,
        items,
        SectionsApi.getAll,
        ItemsApi.getAll,
        populatedItems,
        PopulatedItemsApi.getAll,
        isPopulatedItemExperimentEnabled,
        paging?.limit
      );

      const labelsWithSvg = isLabelLayouterWidgetExperimentEnabled
        ? await getLabelsWithSvgElement(labels?.data)
        : labels?.data;
      const populatedItemsWithLabelsSvg = isLabelLayouterWidgetExperimentEnabled
        ? await Promise.all(
            (populatedItems?.data || []).map(async (populatedItem) => ({
              ...populatedItem,
              labels: await getLabelsWithSvgElement(populatedItem.labels),
            }))
          )
        : populatedItems?.data;

      return {
        data: menus.data.map((menu) =>
          populateMenu(
            menu,
            sections.data,
            items?.data ?? [],
            labelsWithSvg || [],
            modifiers?.data ?? [],
            populatedItemsWithLabelsSvg ?? [],
            shouldFormatModifiersPrice,
            isPopulatedItemExperimentEnabled
          )
        ) as PopulatedMenu[],
      };
    },
  };
};
