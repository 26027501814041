import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';
import { MENU_WIDGET_NAME } from './consts';
import { MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA } from 'root/utils/widgetsDefaultData';

export default createBlocksModel({
  widgetName: MENU_WIDGET_NAME,
  props: {
    preset: {
      type: WidgetPropertyType.STRING,
      defaultValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.preset,
    },
    menusOrder: {
      // TODO: fix this when https://wix.slack.com/archives/CKDB50KE2/p1668693127598739 done
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
    },
    menusDisplayOption: {
      type: WidgetPropertyType.STRING,
      defaultValue: undefined,
    },
    shouldDisplayCurrency: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.shouldDisplayCurrency,
    },
    shouldDisplayVariantCurrency: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.shouldDisplayVariantCurrency,
    },
    zeroPriceDisplayOption: {
      type: WidgetPropertyType.STRING,
      defaultValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.zeroPriceDisplayOption,
    },
    placeholderImageDisplayValue: {
      type: WidgetPropertyType.STRING,
      defaultValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.placeholderImageDisplayValue,
    },
    zeroPriceDisplaySpecificSectionIds: {
      // TODO: fix this when https://wix.slack.com/archives/CKDB50KE2/p1668693127598739 done
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
      defaultValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.zeroPriceDisplaySpecificSectionIds,
    },
    sectionsWithPlaceholderImageIds: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
      defaultValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.sectionsWithPlaceholderImageIds,
    },
    placeholderImage: {
      type: WidgetPropertyType.STRING,
      defaultValue: MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA.placeholderImage,
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
