import type { ControllerParams, Preset, Section } from 'root/types';
import { COLUMNS_WIDGET_COMPONENT_IDS, MENU_WIDGET_PRESET_IDS, PRESET_SECTIONS_IDS_MAPPER } from 'root/utils/consts';
import type { I$W } from '@wix/yoshi-flow-editor';
import type { ColumnsWidgetProps } from './types';
import type { PlaceholderImageDisplayValue, ZeroPriceDisplayValue } from 'root/components/Menu/panels/Settings/types';
import type columnsModel from './model';
import { autorun } from 'mobx';
import { state } from 'root/state/state';
import { divideSectionsIntoColumns } from './utils';

type BindAll = ControllerParams<typeof columnsModel>['$bindAll'];

export class ColumnsController {
  constructor(
    private $w: I$W,
    private $bindAll: BindAll,
    private isMobile: boolean,
    private isUseBindExperimentEnabled: boolean
  ) {}

  init(data: ColumnsWidgetProps) {
    if (this.isUseBindExperimentEnabled) {
      autorun(() => {
        if (state.menu?.sections) {
          this.isMobile && (state.numberOfColumns = 1);
          state.columns = divideSectionsIntoColumns(state.numberOfColumns, state.menu?.sections);
        }
      });

      this.$bindAll({
        [COLUMNS_WIDGET_COMPONENT_IDS.column1]: {
          data: () => ({ sections: state.numberOfColumns > 1 ? state.columns[0] : undefined }),
        },
        [COLUMNS_WIDGET_COMPONENT_IDS.column2]: {
          data: () => ({ sections: state.numberOfColumns === 1 ? state.columns[0] : state.columns[1] }),
        },
        [COLUMNS_WIDGET_COMPONENT_IDS.column3]: {
          data: () => ({ sections: state.numberOfColumns > 1 ? state.columns[2] : undefined }),
        },
      });
    } else {
      this.setSections(
        data.sections,
        data.preset,
        data.shouldDisplayCurrency,
        data.shouldDisplayVariantCurrency,
        data.zeroPriceDisplayOption,
        data.placeholderImageDisplayValue,
        data.zeroPriceDisplaySpecificSectionIds,
        data.sectionsWithPlaceholderImageIds,
        data.placeholderImage
      );
    }
  }

  setColumnsProps(
    columnsIds: string[],
    columns: Section[][],
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[] | undefined,
    sectionsWithPlaceholderImageIds: string[] | undefined,
    placeholderImage: string | undefined
  ) {
    columnsIds.forEach((columnId, i) => {
      this.$w(columnId).data = {
        sections: columns[i],
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        zeroPriceDisplayOption,
        zeroPriceDisplaySpecificSectionIds,
        placeholderImageDisplayValue,
        sectionsWithPlaceholderImageIds,
        placeholderImage,
      };
    });
  }

  setSections(
    sections: Section[],
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[] | undefined,
    sectionsWithPlaceholderImageIds: string[] | undefined,
    placeholderImage: string | undefined
  ) {
    if (this.isMobile) {
      this.$w(PRESET_SECTIONS_IDS_MAPPER.listOneColumn[0]).data = {
        sections,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        zeroPriceDisplayOption,
        zeroPriceDisplaySpecificSectionIds,
        placeholderImageDisplayValue,
        sectionsWithPlaceholderImageIds,
        placeholderImage,
      };
    } else {
      switch (preset) {
        case MENU_WIDGET_PRESET_IDS.listTwoColumn: {
          const columns = divideSectionsIntoColumns(2, sections);
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.listTwoColumn,
            columns,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );

          break;
        }
        case MENU_WIDGET_PRESET_IDS.listThreeColumn: {
          const columns = divideSectionsIntoColumns(3, sections);
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.listThreeColumn,
            columns,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );

          break;
        }
        case MENU_WIDGET_PRESET_IDS.sideBySideTwoColumn: {
          const columns = divideSectionsIntoColumns(2, sections);
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.sideBySideTwoColumn,
            columns,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );
          break;
        }
        case MENU_WIDGET_PRESET_IDS.listOneColumn:
        case MENU_WIDGET_PRESET_IDS.listOneColumnWithImage:
        case MENU_WIDGET_PRESET_IDS.sideBySideOneColumn:
        case MENU_WIDGET_PRESET_IDS.grid: {
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.listOneColumn,
            [sections],
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );
          break;
        }
        default: {
          const columns = divideSectionsIntoColumns(2, sections);
          this.setColumnsProps(
            PRESET_SECTIONS_IDS_MAPPER.listTwoColumn,
            columns,
            preset,
            shouldDisplayCurrency,
            shouldDisplayVariantCurrency,
            zeroPriceDisplayOption,
            placeholderImageDisplayValue,
            zeroPriceDisplaySpecificSectionIds,
            sectionsWithPlaceholderImageIds,
            placeholderImage
          );
        }
      }
    }
  }
}
