import type { Item, ShowcasePopulatedItem } from 'root/types';
import type { HttpClientFactory } from './utils/types';
import { PopulatedItemClientFactory } from '@wix/restaurants-populated-item-client/web';
import { getItem, queryItems } from '@wix/ambassador-restaurants-menus-v1-item/http';
import type { CursorQuery } from '@wix/ambassador-restaurants-menus-v1-item/build/cjs/types.impl';

export const ItemsClient: HttpClientFactory<Item> = (httpClient) => ({
  get: async ({ id }) => {
    return httpClient
      .request(getItem({ itemId: id }))
      .then((res) => {
        const {
          data: { item = {} },
        } = res;

        return { data: item as Item };
      })
      .catch((e) => {
        throw new Error(`Error when calling getItem with id ${id}- ${e}`);
      });
  },
  getAll: async ({ ids, paging }) => {
    const query: CursorQuery = {
      cursorPaging: paging,
    };

    if (Number(ids?.length) > 0) {
      query.filter = {
        id: {
          $in: ids || [],
        },
      };
    }

    return httpClient
      .request(
        queryItems({
          query,
        })
      )
      .then((res) => {
        const {
          data: { items = [], pagingMetadata },
        } = res;

        return { data: items as Item[], pagingMetadata };
      })
      .catch((e) => {
        throw new Error(`Error when calling getItems- ${e}`);
      });
  },
});

export const PopulatedItemsClient: HttpClientFactory<ShowcasePopulatedItem> = (httpClient) => {
  const populatedItemsClient = PopulatedItemClientFactory({
    httpClient,
    populationLevel: { labels: true, modifierGroups: false, modifiers: false, priceVariants: true },
    shouldReadNewVariants: true,
  });
  return {
    get: async ({ id }) => {
      const response = await populatedItemsClient.getById(id);

      if (response.hasError) {
        throw new Error(`Error when calling getItem with id ${id}- ${response.error}`);
      }

      return { data: response.data };
    },
    getAll: async ({ ids, paging }) => {
      const response = await populatedItemsClient.getBulk({ ids, paging });

      if (response.hasError) {
        throw new Error(`Error when calling getItems- ${response.error}`);
      }

      return { data: response.data.data, pagingMetadata: response.data.pagingMetadata };
    },
  };
};
