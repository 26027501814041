import model from './model';
import { ColumnsController } from './columnsController';
import type { ColumnsWidgetProps } from './types';

export default model.createController(({ $w, $bindAll, $widget, flowAPI }) => {
  const { environment, experiments } = flowAPI;
  const isUseBindExperimentEnabled = experiments.enabled('specs.restaurants.useBindInsteadOfW');
  const columnsController = new ColumnsController($w, $bindAll, environment.isMobile, isUseBindExperimentEnabled);

  if (!isUseBindExperimentEnabled) {
    $widget.onPropsChanged((_, { data: nextData }: { data: ColumnsWidgetProps }) => {
      columnsController.init(nextData);
    });
  }

  return {
    pageReady: async () => {
      columnsController.init($widget.props.data);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
