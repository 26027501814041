import model from './model';
import { SectionsController } from './sectionsController';
import type { SectionWidgetProps } from './types';
import type { Section } from 'root/types';
import { didChange } from 'root/utils/lifeCycle';
import { SHOW_PLACEHOLDER_IMAGE_OPTIONS, ZERO_PRICE_DISPLAY_OPTIONS } from 'root/components/Menu/consts';
import isEqual from 'lodash/isEqual';

export default model.createController(({ $w, $bind, $widget, flowAPI, $props }) => {
  const { experiments } = flowAPI;
  const isPlaceholderImageExperimentEnabled = experiments.enabled('specs.restaurants.menus-placeholder-image');
  const isUseBindExperimentEnabled = experiments.enabled('specs.restaurants.useBindInsteadOfW');
  const sectionsController = new SectionsController($w, $bind, $props, isUseBindExperimentEnabled);

  if (!isUseBindExperimentEnabled) {
    $widget.onPropsChanged((prevProps, nextProps) => {
      const {
        sections,
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        zeroPriceDisplayOption,
        zeroPriceDisplaySpecificSectionIds,
        placeholderImageDisplayValue,
        sectionsWithPlaceholderImageIds,
        placeholderImage,
      }: SectionWidgetProps = nextProps.data;
      const {
        sections: prevSections,
        preset: prevPreset,
        shouldDisplayCurrency: prevShouldDisplayCurrency,
        shouldDisplayVariantCurrency: prevShouldDisplayVariantCurrency,
        zeroPriceDisplayOption: prevZeroPriceDisplayOption,
        zeroPriceDisplaySpecificSectionIds: prevZeroPriceDisplaySpecificSectionIds,
        placeholderImageDisplayValue: prevPlaceholderImageDisplayValue,
        sectionsWithPlaceholderImageIds: prevSectionsWithPlaceholderImageIds,
        placeholderImage: prevPlaceholderImage,
      } = prevProps.data;

      const shouldInit = prevPreset !== preset || didChange<Section>(prevSections, sections);

      const shouldUpdatePlaceholderImage =
        prevPlaceholderImage !== placeholderImage ||
        placeholderImageDisplayValue !== prevPlaceholderImageDisplayValue ||
        (placeholderImageDisplayValue === SHOW_PLACEHOLDER_IMAGE_OPTIONS.SPECIFIC &&
          !isEqual(sectionsWithPlaceholderImageIds, prevSectionsWithPlaceholderImageIds));

      const shouldUpdateZeroPriceDisplayOptions =
        prevZeroPriceDisplayOption !== zeroPriceDisplayOption ||
        (zeroPriceDisplayOption === ZERO_PRICE_DISPLAY_OPTIONS.SHOW_ON_SPECIFIC &&
          !isEqual(prevZeroPriceDisplaySpecificSectionIds, zeroPriceDisplaySpecificSectionIds));

      if (shouldInit) {
        sectionsController.init(
          sections,
          preset,
          shouldDisplayCurrency,
          shouldDisplayVariantCurrency,
          zeroPriceDisplayOption,
          placeholderImageDisplayValue,
          zeroPriceDisplaySpecificSectionIds,
          sectionsWithPlaceholderImageIds,
          placeholderImage
        );
      }

      if (prevShouldDisplayCurrency !== shouldDisplayCurrency) {
        sectionsController.updateCurrency(shouldDisplayCurrency);
      }

      if (prevShouldDisplayVariantCurrency !== shouldDisplayVariantCurrency) {
        sectionsController.updateVariantCurrency(shouldDisplayVariantCurrency);
      }

      if (shouldUpdateZeroPriceDisplayOptions) {
        sectionsController.updateZeroPriceDisplayOption(zeroPriceDisplayOption, zeroPriceDisplaySpecificSectionIds);
      }

      if (shouldUpdatePlaceholderImage && isPlaceholderImageExperimentEnabled) {
        sectionsController.updatePlaceholderImageDisplayValue(
          placeholderImageDisplayValue,
          sectionsWithPlaceholderImageIds,
          placeholderImage
        );
      }
    });
  }

  return {
    pageReady: async () => {
      const {
        sections,
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        zeroPriceDisplayOption,
        zeroPriceDisplaySpecificSectionIds,
        placeholderImageDisplayValue,
        sectionsWithPlaceholderImageIds,
        placeholderImage,
      }: SectionWidgetProps = $widget.props.data;
      sectionsController.init(
        sections,
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        zeroPriceDisplayOption,
        placeholderImageDisplayValue,
        zeroPriceDisplaySpecificSectionIds,
        sectionsWithPlaceholderImageIds,
        placeholderImage
      );
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
