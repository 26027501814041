import { listMenus, getMenu } from '@wix/ambassador-restaurants-menus-v1-menu/http';
import type { Menu } from 'root/types';
import type { HttpClientFactory } from './utils/types';

export const MenusClient: HttpClientFactory<Menu> = (httpClient) => ({
  get: async ({ id }) => {
    return httpClient
      .request(getMenu({ menuId: id }))
      .then((res) => {
        const {
          data: { menu = {} },
        } = res;

        return { data: menu as Menu };
      })
      .catch((e) => {
        throw new Error(`Error when calling getMenu with id ${id}- ${e}`);
      });
  },
  getAll: async ({ ids, paging }) => {
    return httpClient
      .request(listMenus({ menuIds: ids, paging }))
      .then((res) => {
        const {
          data: { menus = [], pagingMetadata },
        } = res;

        return { data: menus.filter((menu) => menu.visible) as Menu[], pagingMetadata };
      })
      .catch((e) => {
        throw new Error(`Error when calling getMenus- ${e}`);
      });
  },
});
