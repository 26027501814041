import model from './model';
import { ItemsController } from './itemsController';
import { getPriceFormatter } from 'root/utils/currency';
import type { ItemsWidgetProps } from './types';
import { didChange } from 'root/utils/lifeCycle';

export default model.createController(({ $w, $bind, $props, $widget, flowAPI }) => {
  const priceFormatter = getPriceFormatter(flowAPI);
  const { isViewer, isMobile } = flowAPI.environment;
  const experiments = flowAPI.experiments;
  const isLabelLayouterWidgetExperimentEnabled = experiments.enabled('specs.restaurants.labelsLayouterWidget');
  const isUseBindExperimentEnabled = experiments.enabled('specs.restaurants.useBindInsteadOfW');
  const isPopulatedItemExperimentEnabled = experiments.enabled('specs.restaurants.usePopulatedItemInMenusLivesite');

  const itemsController = new ItemsController(
    $w,
    $bind,
    $props,
    isViewer,
    priceFormatter,
    isLabelLayouterWidgetExperimentEnabled,
    isUseBindExperimentEnabled,
    isPopulatedItemExperimentEnabled
  );

  if (!isUseBindExperimentEnabled) {
    $widget.onPropsChanged((prevProps, nextProps) => {
      const {
        items,
        preset,
        shouldDisplayCurrency,
        shouldDisplayVariantCurrency,
        shouldDisplayZeroPrice,
        shouldDisplayPlaceholderImage,
        placeholderImage,
      }: ItemsWidgetProps = nextProps.data;
      const {
        items: prevItems,
        preset: prevPreset,
        shouldDisplayCurrency: prevShouldDisplayCurrency,
        shouldDisplayVariantCurrency: prevShouldDisplayVariantCurrency,
        shouldDisplayZeroPrice: prevShouldDisplayZeroPrice,
        shouldDisplayPlaceholderImage: prevShouldDisplayPlaceHolderImage,
        placeholderImage: prevPlaceholderImage,
      }: ItemsWidgetProps = prevProps.data;

      if (didChange(prevItems, items)) {
        itemsController.initWithW(
          items,
          preset,
          shouldDisplayCurrency,
          shouldDisplayVariantCurrency,
          shouldDisplayZeroPrice,
          shouldDisplayPlaceholderImage,
          placeholderImage
        );
      } else if (prevPreset !== preset) {
        itemsController.removeImagesFromDOMIfNeeded($w, preset);
      } else if (prevShouldDisplayCurrency !== shouldDisplayCurrency) {
        itemsController.updateCurrency(shouldDisplayCurrency);
      } else if (prevShouldDisplayVariantCurrency !== shouldDisplayVariantCurrency) {
        itemsController.updateVariantCurrency(shouldDisplayVariantCurrency);
      } else if (prevShouldDisplayZeroPrice !== shouldDisplayZeroPrice) {
        itemsController.updateShouldDisplayZeroPrice(shouldDisplayZeroPrice, isPopulatedItemExperimentEnabled);
      } else if (
        placeholderImage !== prevPlaceholderImage ||
        shouldDisplayPlaceholderImage !== prevShouldDisplayPlaceHolderImage
      ) {
        itemsController.updatePlaceholderImage(placeholderImage, shouldDisplayPlaceholderImage);
      }
    });
  }

  return {
    pageReady: async () => {
      if (isUseBindExperimentEnabled) {
        itemsController.init(
          priceFormatter,
          isViewer,
          isMobile,
          isLabelLayouterWidgetExperimentEnabled,
          isPopulatedItemExperimentEnabled
        );
      } else {
        const {
          items,
          preset,
          shouldDisplayCurrency,
          shouldDisplayVariantCurrency,
          shouldDisplayZeroPrice,
          shouldDisplayPlaceholderImage,
          placeholderImage,
        }: ItemsWidgetProps = $widget.props.data;
        itemsController.initWithW(
          items,
          preset,
          shouldDisplayCurrency,
          shouldDisplayVariantCurrency,
          shouldDisplayZeroPrice,
          shouldDisplayPlaceholderImage,
          placeholderImage
        );
      }
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
