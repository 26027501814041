import type { CreateCurrencyFormatterArgs, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const getSiteCurrency = (flowAPI: PlatformControllerFlowAPI) => {
  const { controllerConfig, getCurrencyFormatter } = flowAPI;
  const currencyCode = controllerConfig.wixCodeApi.site.currency;
  return (
    currencyCode &&
    getCurrencyFormatter({ parts: true })({
      currency: currencyCode,
      value: '',
    }).find((part) => part.type === 'currency')?.value
  );
};

export const getPriceFormatter = (flowAPI: PlatformControllerFlowAPI) => {
  const {
    controllerConfig,
    getCurrencyFormatter,
    environment: { multilingual },
    experiments,
  } = flowAPI;
  let locale: string | undefined;
  const currencyCode = controllerConfig.wixCodeApi.site.currency;
  const isFixedPriceFormatExperimentEnabled = experiments.enabled('specs.restaurants.fixed-price-format');

  const siteLanguageFallback = controllerConfig.wixCodeApi.site.language || 'en';
  const siteCountryFallback = 'us';
  const siteCurrencyFallback = 'USD';

  if (multilingual?.isEnabled && multilingual?.currentLanguage) {
    locale = multilingual.siteLanguages.find((lang) => lang.languageCode === multilingual.currentLanguage)?.locale;
  } else {
    locale = controllerConfig.wixCodeApi.site.regionalSettings;
  }

  const [siteLanguage, siteCountry] = locale?.split('-') || '';

  return (price: string, shouldDisplayCurrency: boolean) => {
    let formatterProps: CreateCurrencyFormatterArgs<boolean> = {
      country: siteCountry || siteCountryFallback,
      language: siteLanguage || siteLanguageFallback,
      parts: !shouldDisplayCurrency,
    };

    if (isFixedPriceFormatExperimentEnabled) {
      const [, fractionalPart = ''] = price.split('.');

      let minimumFractionDigits = 0;
      if (fractionalPart.length > 0) {
        minimumFractionDigits = Math.min(3, fractionalPart.length);
      }

      formatterProps = {
        ...formatterProps,
        minimumFractionDigits,
        maximumFractionDigits: 3,
      };
    }

    const formattedPrice = getCurrencyFormatter(formatterProps)({
      currency: currencyCode || siteCurrencyFallback,
      value: price,
    });

    if (shouldDisplayCurrency) {
      return formattedPrice as string;
    }

    return (formattedPrice as Intl.NumberFormatPart[]).reduce((stringPrice, part) => {
      if (part.type !== 'currency') {
        return stringPrice + part.value;
      }
      return stringPrice;
    }, '');
  };
};
