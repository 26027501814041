import type { RootState } from './state';
import type { MenuProps } from 'root/components/Menu/menuController';
import { autorun } from 'mobx';
import type { PlaceholderImageDisplayValue, ZeroPriceDisplayValue } from 'root/components/Menu/panels/Settings/types';
import { getPresetNumberOfColumns } from 'root/components/Columns/utils';

export const observeProps = (props: MenuProps, state: RootState) => {
  autorun(() => {
    state.preset = props.preset;
    state.numberOfColumns = getPresetNumberOfColumns(props.preset);
  });

  autorun(() => {
    state.shouldDisplayCurrency = props.shouldDisplayCurrency;
  });

  autorun(() => {
    state.shouldDisplayVariantCurrency = props.shouldDisplayVariantCurrency;
  });

  autorun(() => {
    state.zeroPriceDisplayOption = props.zeroPriceDisplayOption as ZeroPriceDisplayValue;
  });

  autorun(() => {
    state.zeroPriceDisplaySpecificSectionIds = props.zeroPriceDisplaySpecificSectionIds;
  });

  autorun(() => {
    state.placeholderImageDisplayValue = props.placeholderImageDisplayValue as PlaceholderImageDisplayValue;
  });

  autorun(() => {
    state.sectionsWithPlaceholderImageIds = props.sectionsWithPlaceholderImageIds;
  });

  autorun(() => {
    state.placeholderImage = props.placeholderImage;
  });
};
