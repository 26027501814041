import type { Preset, Section } from 'root/types';
import { THREE_COLUMNS_PRESETS, TWO_COLUMNS_PRESETS } from 'root/utils/consts';
import { divideSectionsIntoThreeColumns, divideSectionsIntoTwoColumns } from './divideSectionsIntoColumns';

export const getPresetNumberOfColumns = (preset: Preset) => {
  if (TWO_COLUMNS_PRESETS.includes(preset)) {
    return 2;
  }
  if (THREE_COLUMNS_PRESETS.includes(preset)) {
    return 3;
  }
  return 1;
};

export const divideSectionsIntoColumns = (colNum: number, sectionsToDivide: Section[]) => {
  switch (colNum) {
    case 1:
      return [sectionsToDivide];
    case 2:
      return divideSectionsIntoTwoColumns(sectionsToDivide);
    case 3:
      return divideSectionsIntoThreeColumns(sectionsToDivide);
    default:
      return divideSectionsIntoTwoColumns(sectionsToDivide);
  }
};
