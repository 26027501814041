import type { ControllerParams, ModifierWithPrice } from 'root/types';
import type { I$W } from '@wix/yoshi-flow-editor';
import { PRICE_VARIANTS_WIDGET_COMPONENT_IDS } from './consts';
import { setPriceContainer } from 'root/utils/priceDisplay';
import type variantsModel from './model';
import { state } from 'root/state/state';
import { getShouldDeleteZeroPrice } from 'root/utils/settingsUtils';

type Bind = ControllerParams<typeof variantsModel>['$bind'];
type Props = ControllerParams<typeof variantsModel>['$props'];

export class VariantsController {
  constructor(
    private $w: I$W,
    private $bind: Bind,
    private $props: Props,
    private priceFormatter?: (price: string, shouldDisplayVariantCurrency: boolean) => string | undefined,
    isUseBindExperimentEnabled?: boolean
  ) {
    if (!isUseBindExperimentEnabled) {
      this.$w(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater)?.onItemReady?.(
        async (
          $item: I$W,
          itemData: ModifierWithPrice & {
            _id: string;
            shouldDisplayVariantCurrency: boolean;
            shouldDisplayZeroPrice: boolean;
            isLast: boolean;
          }
        ) => {
          $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantName).text = itemData.name;

          const variantDivider = $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantDivider);
          if (itemData.isLast) {
            variantDivider.delete();
          } else {
            variantDivider.restore();
          }

          const variantHasNoPrice = Number(itemData.price || '0.00') === 0;
          const variantPriceContainer = $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice);
          if (variantHasNoPrice && !itemData.shouldDisplayZeroPrice) {
            await variantPriceContainer.delete();
          } else {
            variantPriceContainer.deleted && (await variantPriceContainer.restore());
            $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice).text = this.priceFormatter?.(
              itemData.price || '0.00',
              itemData.shouldDisplayVariantCurrency
            );
          }
        }
      );
    }
  }

  init(priceFormatter: (price: string, shouldDisplayVariantCurrency: boolean) => string) {
    this.$bind(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater, {
      data: () =>
        (this.$props.data?.priceVariants || []).map(({ id, ...rest }: ModifierWithPrice, index: number) => ({
          _id: `${id}-${index}`,
          isLast: index === (this.$props.data?.priceVariants || []).length - 1,
          sectionId: this.$props.data?.sectionId,
          ...rest,
        })),
      item(item: ModifierWithPrice & { isLast: boolean; sectionId: string }, $bindItem) {
        $bindItem(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantName, {
          text: () => item.name || '',
        });
        $bindItem(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice, {
          text: () => priceFormatter(item.price || '0.00', state.shouldDisplayVariantCurrency),
          deleted: () =>
            getShouldDeleteZeroPrice({
              price: item.price,
              zeroPriceDisplayOption: state.zeroPriceDisplayOption,
              sectionId: item.sectionId,
              zeroPriceDisplaySpecificSectionIds: state.zeroPriceDisplaySpecificSectionIds,
            }),
        });
        $bindItem(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantDivider, {
          deleted: () => item.isLast,
        });
      },
    });
  }

  updateCurrency(shouldDisplayVariantCurrency: boolean) {
    this.$w(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater)?.onItemReady?.(
      ($item: I$W, itemData: ModifierWithPrice & { _id: string }) => {
        $item(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice).text = this.priceFormatter?.(
          itemData.price || '0.00',
          shouldDisplayVariantCurrency
        );
      }
    );
  }

  updateShouldDisplayZeroPrice(shouldDisplayZeroPrice: boolean) {
    this.$w(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater)?.onItemReady?.(
      async ($item: I$W, itemData: ModifierWithPrice & { _id: string; shouldDisplayVariantCurrency: boolean }) => {
        setPriceContainer(
          $item,
          PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice,
          itemData.price,
          shouldDisplayZeroPrice,
          itemData.shouldDisplayVariantCurrency,
          this.priceFormatter
        );
      }
    );
  }

  initWithW(
    priceVariants: ModifierWithPrice[],
    shouldDisplayVariantCurrency: boolean,
    shouldDisplayZeroPrice: boolean
  ) {
    this.$w(PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantsRepeater).data = priceVariants.map(
      ({ id, ...rest }, index) => ({
        _id: `${id}-${index}`,
        shouldDisplayVariantCurrency,
        shouldDisplayZeroPrice,
        isLast: index === priceVariants.length - 1,
        ...rest,
      })
    );
  }
}
