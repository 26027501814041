import type { Modifier } from 'root/types';
import type { HttpClientFactory } from './utils/types';
import { listModifiers, getModifier } from '@wix/ambassador-restaurants-menus-v1-item-modifier/http';

export const ModifiersClient: HttpClientFactory<Modifier> = (httpClient) => ({
  get: async ({ id }) => {
    return httpClient
      .request(getModifier({ modifierId: id }))
      .then((response) => ({ data: response.data.modifier as Modifier }))
      .catch((e) => {
        throw new Error(`Error when calling getModifier with id ${id}- ${e}`);
      });
  },
  getAll: async () => {
    return httpClient
      .request(listModifiers({}))
      .then((response) => ({ data: response.data.modifiers as Modifier[] }))
      .catch((e) => {
        throw new Error(`Error when calling listModifiers- ${e}`);
      });
  },
});
