import type { LABELS_WIDGET_COMPONENT_IDS } from 'root/components/Label Layouter/consts';
import { LABELS_LIMIT } from 'root/components/Label Layouter/consts';
import type { Label, LabelWithSvg } from 'root/types';

export const getLabelCompElementIds = (compId: string) =>
  Array.from(
    Array(LABELS_LIMIT),
    (_, index) => `${compId}${(index + 1).toString()}` as keyof typeof LABELS_WIDGET_COMPONENT_IDS
  );

export const getLabelsWithSvgElement = async (labels?: Label[]): Promise<LabelWithSvg[]> => {
  const labelsWithSvgElement = await Promise.all(
    labels?.map(async (label) => {
      const svgUrl = label.icon?.url;
      if (svgUrl) {
        try {
          const svgResponse = await fetch(svgUrl);
          const svgElement = await svgResponse.text();
          if (svgElement) {
            return { ...label, svgId: svgElement };
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Error fetching SVG:', error);
        }
      }
      return label;
    }) || []
  );
  return labelsWithSvgElement;
};
