import { LABELS_LIMIT, ITEMS_WIDGET_COMPONENT_IDS } from './consts';
import type { ItemImage, Item, Preset } from 'root/types';
import type { I$W } from '@wix/yoshi-flow-editor';
import { MENU_WIDGET_PRESET_WITH_IMG_IDS, ELEMENT_PROPERTY } from 'root/utils/consts';
import { setElementDataAndCollapseIfNeeded } from 'root/utils/setElementDataAndCollapseIfNeeded';
import { getShouldDisplayPlaceholderImage } from 'root/utils/settingsUtils';
import type { PlaceholderImageDisplayValue } from 'root/components/Menu/panels/Settings/types';

export const buildImgSrc: (image: ItemImage) => string = ({ width, height, id }) => {
  return `wix:image://v1/${id}/#originWidth=${width}&originHeight=${height}`;
};

export const getLabelCompElementIds = (compId: string) =>
  Array.from(
    Array(LABELS_LIMIT),
    (_, index) => `${compId}${(index + 1).toString()}` as keyof typeof ITEMS_WIDGET_COMPONENT_IDS
  );

export const setImageDataOrRemoveFromDOMIfNeeded = (
  $item: I$W,
  itemData: Item,
  preset: Preset,
  placeholderImage?: string,
  isViewer?: boolean
) => {
  const shouldDeleteImageFromDOM = isViewer && preset && !MENU_WIDGET_PRESET_WITH_IMG_IDS.includes(preset);
  const imageElement = $item(ITEMS_WIDGET_COMPONENT_IDS.itemImage);
  if (shouldDeleteImageFromDOM) {
    !imageElement.deleted && imageElement.delete();
  } else {
    imageElement.deleted && imageElement.restore();
    const { image } = itemData;
    const imageUrl = image?.url;
    const src = placeholderImage && !imageUrl ? placeholderImage : imageUrl && buildImgSrc(image);

    setElementDataAndCollapseIfNeeded($item, ITEMS_WIDGET_COMPONENT_IDS.itemImage, ELEMENT_PROPERTY.SRC, src);
    imageUrl && (imageElement.alt = `${itemData.name} image`);
  }
};

export const getPlaceholderImage = ({
  shouldDisplayPlaceholderImage,
  placeholderImage,
}: {
  shouldDisplayPlaceholderImage: boolean;
  placeholderImage: string;
}) => {
  return shouldDisplayPlaceholderImage ? placeholderImage : undefined;
};

export const getImageSrc = ({
  placeholderImageDisplayValue,
  sectionId,
  sectionsWithPlaceholderImageIds,
  placeholderImage,
  image,
}: {
  placeholderImageDisplayValue: PlaceholderImageDisplayValue;
  sectionId: string;
  sectionsWithPlaceholderImageIds: string[] | undefined;
  placeholderImage: string;
  image: Item['image'];
}) => {
  if (image?.url) {
    return buildImgSrc(image);
  }

  const shouldDisplayPlaceholderImage = getShouldDisplayPlaceholderImage(
    placeholderImageDisplayValue,
    sectionId,
    sectionsWithPlaceholderImageIds
  );

  return getPlaceholderImage({ shouldDisplayPlaceholderImage, placeholderImage });
};
